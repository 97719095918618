.follower-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2rem;
  width: 100%;
}


h3:first-child.title {
  font-size: 3rem;
  margin-top: 2rem;
  color: black;
}
