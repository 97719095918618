.App {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .ui.card {
    width: 28rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin-bottom: 1rem;
    }

    >.extra {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    i.icon {
        color: black;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }
    >.content>.description {
        text-align: center;
    }
}

.ui.card:first-child {
    margin-top: 1em;
}