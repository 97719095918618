@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");


.navbar, .title {
  display: flex;
  flex-direction: row;
  background: black;
  height: 7rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 3rem;
  font-family: 'Roboto', sans-serif;

  .navbar-left {
      min-width: 22rem;
      padding-left: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
  }

  .icon-text {
    color: white;
    font-family: 'Roboto', sans-serif;
    }   
}

i.icon {
  color: white;
}


.icon-text {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EBEDEF;
    padding-right: 0;
}

.title h3 {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    margin-bottom: 0;

}
