@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  align-items: center;
}

html {
  font-size: 62.5%;
}



.App {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .App .ui.card {
    width: 28rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin-bottom: 1rem; }
  .App > .extra {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .App i.icon {
    color: black; }
  .App .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .App > .content > .description {
    text-align: center; }

.ui.card:first-child {
  margin-top: 1em; }

.navbar, .title {
  display: flex;
  flex-direction: row;
  background: black;
  height: 7rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 3rem;
  font-family: 'Roboto', sans-serif; }
  .navbar .navbar-left, .title .navbar-left {
    min-width: 22rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .navbar .icon-text, .title .icon-text {
    color: white;
    font-family: 'Roboto', sans-serif; }

i.icon {
  color: white; }

.icon-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem; }

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEDEF;
  padding-right: 0; }

.title h3 {
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  margin-bottom: 0; }

.follower-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2rem;
  width: 100%; }

h3:first-child.title {
  font-size: 3rem;
  margin-top: 2rem;
  color: black; }

form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30rem; }
  form .ui.button {
    font-size: 1.3rem; }

